/* eslint no-underscore-dangle: 0, no-undef: 0 */
import React, { Fragment} from 'react'
import { Global, css } from '@emotion/core'
import { hydrate, render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Authorization from 'Auth'
import { Grid } from 'react-flexbox-grid'
import 'index.css'
import App from 'App'

const rootElement = document.getElementById("root")

const Index = () => (
  <Fragment>
    <Global
      styles={css`
        @font-face {
          font-family: 'Futura';
          src: url('/fonts/FuturaMedium.woff') format('woff');
          font-style: normal;
        }

        @font-face {
          font-family: 'Futura';
          src: url('/fonts/FuturaHeavy.woff') format('woff');
          font-weight: bold;
        }
        html {
          font-size: 100%;
        }
        * {
          font-family: 'Futura', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
        }
        body {
          margin: 0;
          font-family: 'Futura', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      `}
    />
    <Grid fluid>
      <BrowserRouter>
        <Authorization>
          <App />
        </Authorization>
      </BrowserRouter>
    </Grid>
  </Fragment>
)

if (rootElement.hasChildNodes()) {
  hydrate(<Index />, rootElement)
} else {
  render(<Index />, rootElement)
}

import React from 'react'
import { Form, Field } from 'react-final-form'
import { Col, Row } from 'react-flexbox-grid'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'

const StyledForm = styled.form({

})

const Edit = (props) => {
  const { initialValues } = props

  return (
    <StyledForm>
      <Row>
        <Col xs={12}>
          <p>{initialValues.attributes.email}</p>
        </Col>
      </Row>
    </StyledForm>
  )
}

export default Edit

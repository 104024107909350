import React from 'react'
import Spinner from 'react-spinkit'
import styled from '@emotion/styled'
import { styles as s, colors } from 'stylesheet'

const StyledContainer = styled.div(s.flex, s.grow1, s.alignCenter, s.justifyCenter, {
  width: '100%',
})

const Loading = () => (
  <StyledContainer>
    <Spinner name="double-bounce" color={colors.black} />
  </StyledContainer>
);

export default Loading;

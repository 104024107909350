import React, { useState, useEffect } from 'react'
import { node, shape, string } from 'prop-types'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import {
  Switch,
  Route,
  Redirect,
  withRouter,
} from 'react-router-dom'
import { InMemoryCache } from 'apollo-boost'
import firebase from 'firebase/app'
import 'firebase/auth'
import AuthContext from 'context/AuthContext'
import { concat } from 'apollo-link'
import Loadable from 'react-loadable'
import { FULFILLED, IDLE, PENDING, REJECTED } from 'config/status'
import Loading from 'components/Loading'
import ResetPassword from 'ResetPassword'

const LoadableLogin = Loadable({
  loader: () => import('views/Login'),
  loading: Loading,
})

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_WEB_API_KEY
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID
const FIREBASE_SENDER_ID = process.env.REACT_APP_FIREBASE_SENDER_ID

firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
  messageSenderId: FIREBASE_SENDER_ID,
});

const firebaseAuth = firebase.auth()

const link = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT })

const authLink = setContext(async (operation, { headers }) => {
  const token = await firebaseAuth.currentUser.getIdToken(true)
  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  }
})

const Authorization = (props) => {
  const { children, history, location: { key } } = props
  const [initialized, setInitialized] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [authStatus, setAuthStatus] = useState(IDLE)
  const [resetStatus, setResetStatus] = useState(IDLE)
  const [signUpStatus, setSignUpStatus] = useState(IDLE)
  const [error, setError] = useState(null)

  useEffect(() => firebaseAuth.onAuthStateChanged(async (user) => {
    setError(null)
    setAuthStatus(PENDING)
    if (user) {
      const token = await user.getIdToken(true)
      if (token) {
        setLoggedIn(true)
      } else {
        setLoggedIn(false)
      }

      setAuthStatus(FULFILLED)
      setInitialized(true)
    } else {
      setLoggedIn(false)
      setAuthStatus(IDLE)
      setInitialized(true)
    }
  }), [])

  useEffect(() => async () => {
    try {
      setResetStatus(IDLE)
      setError(null)
      setInitialized(true)
    } catch (e) {
      setLoggedIn(false)
      setAuthStatus(IDLE)
      setInitialized(true)
    }
  }, [key])

  if (!initialized) return <Loading />

  const signInUser = async (email = '', password = '') => {
    try {
      setAuthStatus(PENDING)
      setError(null)
      await firebaseAuth.signInWithEmailAndPassword(email, password).catch((e) => {
        setError(e)
        setAuthStatus(REJECTED)
      })
      return true
    } catch (e) {
      return e
    }
  }

  const resetPassword = async (email) => {
    try {
      setError(null)
      setResetStatus(PENDING)
      return await firebase.auth().sendPasswordResetEmail(email)
        .then(() => {
          setResetStatus(FULFILLED)
        })
        .catch((e) => {
          setError(e)
          setResetStatus(REJECTED)
        })
    } catch (e) {
      return e
    }
  }

  const signOutUser = async () => {
    try {
      await firebaseAuth.signOut()
      setLoggedIn(false)
      setAuthStatus(IDLE)
      return true
    } catch (e) {
      return e
    }
  }

  const signUpUser = async (email = '', password = '') => {
    try {
      setSignUpStatus(PENDING)
      setError(null)
      await firebaseAuth.createUserWithEmailAndPassword(email, password)
        .then(() => {
          setSignUpStatus(FULFILLED)
          history.push('/')
        })
        .catch((e) => {
          setError(e)
          setSignUpStatus(REJECTED)
        })
      return true
    } catch (e) {
      return e
    }
  }

  const authContext = {
    actions: {
      resetPassword,
      signInUser,
      signOutUser,
      signUpUser,
    },
    error,
    auth: firebaseAuth,
    status: {
      auth: authStatus,
      reset: resetStatus,
      signUp: signUpStatus,
    },
  }

  const client = new ApolloClient({
    link: !loggedIn ? link : concat(authLink, link),
    cache: new InMemoryCache(),
  })

  return (
    <AuthContext.Provider value={authContext}>
      <Switch>
        <Route path="/usermgmt" component={ResetPassword} />
        <Route
          path="*"
          render={() => (
            <ApolloProvider client={client}>
              {!loggedIn ? (
                <Switch>
                  <Route
                    path="/"
                    component={LoadableLogin}
                  />
                  <Redirect from="*" to="/" />
                </Switch>
              ) : children}
            </ApolloProvider>
          )}
        />
      </Switch>
    </AuthContext.Provider>
  )
}

Authorization.propTypes = {
  children: node.isRequired,
  location: shape({
    key: string,
  }).isRequired,
  history: shape().isRequired,
}

export default withRouter(Authorization)

import React, { useState } from 'react'
import { Query } from 'react-apollo'
import { Form, Field } from 'react-final-form'
import { Col, Row } from 'react-flexbox-grid'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import Edit from './Edit'

const StyledForm = styled.form({

})

const GET_ME = gql`
  query User {
    me {
      uid
      attributes {
        email
        firstName
        lastName
        postId
      }
    }
  }
`

const Me = () => {
  const [data, setData] = useState(null)

  const renderBody = (queryProps) => {
    const {
      data: responseData,
      loading,
    } = queryProps
    console.log(queryProps)

    if (!loading && responseData && responseData.me) {
      setData(responseData.me)
    }

    if ((loading && !data) || (!data)) return <p className='mb3'>Loading Profile...</p>

    return <Edit initialValues={data} />
  }

  return (
    <Query query={GET_ME}>
      {renderBody}
    </Query>
  )
}

export default Me

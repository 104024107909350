import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import { Col, Row } from 'react-flexbox-grid'
import { Form, Field } from 'react-final-form'
import { withAuth } from 'context/AuthContext'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import Loading from 'components/Loading'

const StyledView = styled.div(
  {
    minHeight: '100vh',

    '.logo': {
      marginBottom: '2rem',
    },

    '.submit-button': {
      cursor: 'pointer',
    },

    input: css(s.white, {
      width: '100%',
      backgroundColor: 'inherit',
      border: 0,
      borderBottom: '2px solid white',
      borderRadius: '0',
      webkitBorderRadius:'0px',
      fontSize: '1rem',
      lineHeight: '1.75rem',
    }),

    'button[type=submit]': css(s.bgRed, s.white, {
      width: '100%',
      border: 0,
      borderRadius: '0.25rem',
      fontSize: '1rem',
      padding: '1rem 0',

      '&:disabled': {
        opacity: '0.5',
      },
    }),
  },
)

const StyledForm = styled.form({
  width: '100%',

  '.fields': css(s.bgGrey, {
    paddingTop: '1.5rem',
    paddingBottom: '1.75rem',
    marginBottom: '2rem',

    '.field': {
      marginBottom: '2rem',
    }
  }),

  '.label': {
    fontSize: '.85rem',
    padding: '5px',
    color: colors.white,
    backgroundColor: colors.red,
    display: 'block',
    marginBottom: '1rem',
    borderRadius: '5px',
  },
})

const ResetPassword = (props) => {
  const { auth: { auth }, location } = props
  const [email, setEmail] = useState()
  const [success, setSuccess] = useState()
  const [submitError, setSubmitError] = useState()

  const searchParams = new URLSearchParams(location.search)
  const oobCode = searchParams.get('oobCode')
  const mode = searchParams.get('mode')

  useEffect(() => {
    auth
      .verifyPasswordResetCode(oobCode)
      .then(setEmail)
      .catch(setSubmitError)
  }, [auth, oobCode])

  if (!mode || !oobCode) return <Redirect to={'/'} />

  const onSubmit = (values) => {
    auth
      .confirmPasswordReset(oobCode, values.password)
      .then(() => setSuccess(true))
      .catch(setSubmitError)
  }

  return (
    <StyledView as={Row} middle='xs' center='xs'>
      <Col xs={12} lg={6}>
        <h1>Reset Your Password</h1>
        {success && (
          <h4>Your password has been successfully reset!</h4>
        )}
        {!email && submitError && (
          <h4>{submitError.message}</h4>
        )}
        {email && !success ? (
          <>
            <h4>{email}</h4>
            <Row>
              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {}
                  if (!values.password || values.password === "") {
                    errors.password = 'Required'
                  }

                  if (values.password !== values.password2) {
                    errors.form = 'Passwords do not match'
                  }
                  return errors
                }}
                render={(formProps) => {
                  const { errors, handleSubmit, invalid, submitting } = formProps

                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <div className='fields'>
                        <Col xs={10} xsOffset={1}>
                          <Field
                            className='field'
                            label="password"
                            type="password"
                            name="password"
                            component="input"
                            placeholder="New Password"
                          />
                        </Col>
                        <Col xs={10} xsOffset={1}>
                          <Field
                            className='field'
                            label="password"
                            type="password"
                            name="password2"
                            component="input"
                            placeholder="New Password Again"
                          />
                        </Col>
                        {errors.form && (
                          <Col xs={10} xsOffset={1}>
                            <p className='error'>{errors.form}</p>
                          </Col>
                        )}
                        {submitError && (
                          <Col xs={10} xsOffset={1}>
                            <p className='error'>{submitError.message}</p>
                          </Col>
                        )}
                        <Col xs={10} xsOffset={1}>
                          <button
                            className='submit-button'
                            onClick={handleSubmit}
                            type='submit'
                            disabled={invalid || submitting}
                          >
                            Reset Password
                          </button>
                        </Col>
                      </div>
                    </StyledForm>
                  )
                }}
              />
            </Row>
          </>
        ) : <Loading />}
      </Col>
    </StyledView>
  )
}

export default withAuth(withRouter(ResetPassword))

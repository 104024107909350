import React from 'react'
import { withAuth } from 'context/AuthContext'
import { Col, Row } from 'react-flexbox-grid'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import Me from 'views/Me'

const StyledView = styled.div(
  {
    minHeight: '100vh',

    '.logo': {
      marginBottom: '2rem',
    },

    '.submit-button': {
      cursor: 'pointer',
    },

    '.lead-text': {
      fontSize: '1.25rem',
      color: colors.lightGrey,
    },

    '.text-gray': {
      color: colors.lightGrey,
    },

    '.text-uppercase': {
      textTransform: 'uppercase',
    },

    '.letter-spacing': {
      letterSpacing: '2px',
    },

    '.mb5': {
      marginBottom: '5rem',
    },

    '.mb3': {
      marginBottom: '3rem',
    },

    '.badge': css({
      maxHeight: '40px',
      margin: '2rem auto',
    }),

    img: css(s.block, {
      maxWidth: '100%',
    }),

    nav: {
      marginBottom: '2rem',
      textAlign: 'left',

      a: css(s.lightGrey, {
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginRight: '1rem',
        '&.active': css(s.white),
      }),
    },

    input: css(s.white, {
      width: '100%',
      backgroundColor: 'inherit',
      border: 0,
      borderBottom: '2px solid white',
      fontSize: '1rem',
      lineHeight: '1.75rem',
    }),

    '.log-out': css(s.bgRed, s.white, {
      width: '100%',
      border: 0,
      borderRadius: '0.25rem',
      fontSize: '1rem',
      padding: '1rem 0',
    }),
  },
)

const App = (props) => {
  const {
    auth: {
      actions,
    }
  } = props

  return (
    <StyledView as={Row} middle='xs' center='xs'>
      <Col xs={12} md={8} lg={6} xl={4}>
        <Row center='xs' className="mb5">
          <Col xs={12}>
            <Row>
              <Col xs={12}>
                <h3 className='text-uppercase letter-spacing'>You’re all Set</h3>
                <div className='text-gray mb3'>We’ll see you at the event. Be sure to download the app and log in before you arrive to stay up to date on all the details.</div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <h3 className='text-uppercase letter-spacing'>Download the App</h3>
                <div className='text-gray mb4'>We’ll see you at the event. Be sure to download the app and log in before you arrive to stay up to date on all the details.</div>
                <Row>
                  <Col xs={6}>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.automotiveevents.dashboard'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img className='badge' src='/images/google-play-badge.png' alt='Download in the Google Play Store' />
                    </a>
                  </Col>
                  <Col xs={6}>
                    <a
                      href='https://apps.apple.com/us/app/dashboard-ae/id1325735319'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img className='badge' src='/images/app-store-badge.svg' alt='Download in App Store' />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Me />
          </Col>
          <Col xs={12}>
            <button className='log-out submit-button' onClick={actions.signOutUser}>Log Out</button>
          </Col>
        </Row>
      </Col>
    </StyledView>
  )
}

export default withAuth(App)
